<template>
  <v-card class="mx-auto" :disabled="ldg" :loading="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small plain icon :to="{ name: 'pend_cobros' }">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row dense>
        <v-col cols="12" v-if="data.rs.discharged">
          <v-alert type="success" class="text-center mb-0" dense>
            La solicitud fue terminada el {{ data.rs.discharged }}
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'SOLICITUD'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <span
                    class="text-caption font-weight-bold"
                    v-text="'Folio'"
                  />
                  <br />
                  <span class="text-description" v-text="data.folio" />
                  <v-tooltip v-if="log.permissions.rs_letters.read" left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="info"
                        x-small
                        icon
                        :to="{
                          name: 'servicios.cartas',
                          params: { id: data.rs_id },
                        }"
                      >
                        <v-icon small> mdi-eye </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Detalle'" />
                  </v-tooltip>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Tipo" :value="data.rs.rs_type.type" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <span
                    class="text-caption font-weight-bold"
                    v-text="'Ingreso folio'"
                  />
                  <br />
                  <span
                    class="text-description"
                    v-text="data.rha ? data.rha.folio : ''"
                  />
                  <v-tooltip v-if="data.rha && log.permissions.rhas.read" left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="info"
                        x-small
                        icon
                        :to="{
                          name: 'admission_request.detalle',
                          params: { id: data.rha.id },
                        }"
                      >
                        <v-icon small> mdi-eye </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Detalle'" />
                  </v-tooltip>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <span class="text-caption font-weight-bold" v-text="'INFO'" />
                  <br />
                  <v-icon v-if="data.rs.external" color="success" small>
                    mdi-check
                  </v-icon>
                  <v-icon v-else small> mdi-close </v-icon>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <span
                    class="text-caption font-weight-bold"
                    v-text="'Confirmación'"
                  />
                  <br />
                  <v-icon v-if="data.confirm_val == 1" color="success" small>
                    mdi-check
                  </v-icon>
                  <v-icon v-if="data.confirm_val == 0" color="warning" small>
                    mdi-close
                  </v-icon>
                  <v-icon v-if="data.confirm_val == 2" color="error" small>
                    mdi-null
                  </v-icon>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Correo electrónico" :value="data.rs.email" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Correo electrónico alterno"
                    :value="data.rs.email2"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Teléfono" :value="data.rs.phone" />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Detalle de la solicitud"
                    :value="data.rs.detail"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'ASEGURADO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="black"
                      x-small
                      text
                      dark
                      @click.prevent="sinisterRepDlg(data.rs.insured)"
                    >
                      <v-icon small> mdi-file-chart </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Reporte Siniestralidad'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Nombre" :value="data.rs.insured.full_name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Núm. credencial"
                    :value="data.rs.insured.credential"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="ID SM" :value="data.rs.enrollment" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Contratante"
                    :value="data.rs.insured.contractor.name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Oficina"
                    :value="data.rs.insured.office_address"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="F. Nacimiento"
                    :value="data.rs.insured.birthday"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Vigencia"
                    :value="data.rs.insured.validity"
                    :class="data.rs.insured.expired ? 'red--text' : ''"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <div
                    class="text-caption font-weight-bold"
                    v-text="'Trat. méd'"
                  />
                  <v-icon v-if="data.rs.insured.medical_treatment" small>
                    mdi-close
                  </v-icon>
                  <v-icon v-else color="success" small> mdi-check </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="data.provider_id" cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'PROVEEDOR'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text v-if="data != null">
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <ViewData label="Nombre" :value="data.provider.trade_name" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData label="IDP" :value="data.provider.id" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="P. tipo"
                    :value="data.provider.provider_type.provider_type"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-else cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'MÉDICO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text v-if="data != null">
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <ViewData label="Nombre" :value="data.doctor.full_name" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData label="IDM" :value="data.doctor.id" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Especialidad"
                    :value="data.specialty_type.specialty_type"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Consultorio"
                    :value="data.provider ? data.provider.trade_name : null"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'CARTA'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="pink darken-2"
                      x-small
                      text
                      dark
                      @click.prevent="letterView"
                    >
                      <v-icon small> mdi-file-certificate </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Ver carta'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text v-if="data != null">
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Monto"
                    :value="numberFormat(data.assured_amount)"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData label="Fecha" :value="data.letter_generated" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Generada por"
                    :value="data.letter_generated_by.email"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData label="Observaciones" :value="data.caption" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'REGISTRO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="data.rs.created_by.email"
                    :subvalue="data.rs.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="data.rs.updated_by.email"
                    :subvalue="data.rs.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>
    </v-card-text>

    <v-dialog v-model="sinister_rep_dlg" scrollable persistent max-width="1800">
      <v-card
        tile
        :disabled="sinister_rep_dlg_ldg"
        :loading="sinister_rep_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> SINIESTRALIDAD </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="sinister_rep_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="sinister_rep">
          <v-row dense>
            <v-col cols="12" />
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h4 v-text="insured.credential + ' | ' + insured.full_name" />
            </v-col>
          </v-row>
          <v-row dense v-for="(icd, i) in sinister_rep.list" :key="i">
            <v-col cols="12">
              <span class="text-caption" v-text="icd.code + ' | ' + icd.icd" />
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Tipo'" />
                      <th v-text="'Proveedor'" />
                      <th v-text="'T. Prov. | Espec.'" />
                      <th v-text="'Folio'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Padecimiento'" />
                      <th v-text="'Siniestro'" />
                      <th v-text="'Núm. Siniestro'" />
                      <th v-text="'Deducible'" />
                      <th v-text="'Coaseguro %'" />
                      <th v-text="'Facturado'" />
                      <th v-text="'Monto'" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in icd.items" :key="i">
                      <td v-text="`${i + 1}`" />
                      <td v-text="item.type" />
                      <td v-text="item.provider" />
                      <td v-text="item.provider_type" />
                      <td v-text="item.folio" />
                      <td v-text="item.date" />
                      <td v-text="item.accident ? 'ACCIDENTE' : 'ENFERMEDAD'" />
                      <td
                        v-text="item.initial ? 'INICIAL' : 'COMPLEMENTARIO'"
                      />
                      <td v-text="item.sinister_number" />
                      <td v-text="numberFormat(item.deducible_amount)" />
                      <td v-text="item.coinsurance" />
                      <td>
                        <v-icon small :color="item.bill ? 'success' : ''">
                          mdi-{{ item.bill ? "check" : "close" }}
                        </v-icon>
                      </td>
                      <td v-text="numberFormat(item.amount)" />
                      <td>
                        <v-tooltip v-if="item.comment" left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              dark
                              x-small
                              class="ml-1"
                              color="warning"
                              @click="commentDialog(item)"
                            >
                              <v-icon> mdi-message-draw </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Seg. médico comentario'" />
                        </v-tooltip>
                        <v-tooltip
                          v-if="log.permissions.rhas.read && item.rha_id"
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              dark
                              x-small
                              class="ml-1"
                              color="info"
                              :to="{
                                name: 'admission_request.detalle',
                                params: { id: item.rha_id },
                              }"
                            >
                              <v-icon> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Ver ingreso'" />
                        </v-tooltip>
                        <v-tooltip
                          v-if="log.permissions.rss.read && item.rs_id"
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              dark
                              x-small
                              class="ml-1"
                              color="info"
                              :to="{
                                name: 'servicios.detalle',
                                params: { id: item.rs_id },
                              }"
                            >
                              <v-icon> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Ver servicio'" />
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <b v-text="numberFormat(icd.amount)" />
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  numberFormat,
  msgAlert,
  URL_PDF,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    ViewData,
  },
  data() {
    return {
      numberFormat: numberFormat,
      id: parseInt(this.$route.params.rs_service_id),
      log: this.$store.getters.getLogin,
      data: null,
      ldg: true,
      sinister_rep: null,
      sinister_rep_dlg_ldg: false,
      sinister_rep_dlg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      url_pdf: URL_PDF,
    };
  },
  methods: {
    getData() {
      this.ldg = true;
      this.data = null;

      Axios.get(
        URL_API + "/rss/services/" + this.id + "/bills/pending",
        headersToken(this.log.token)
      ).then((rsp) => {
        if (rsp.data.success) {
          this.data = rsp.data.data;
          this.ldg = false;
        } else {
          this.$swal.fire(msgAlert("error", "Contacte al equipo de soporte"));
        }
      });
    },

    sinisterRepDlg(insured) {
      this.insured = {
        credential: insured.credential,
        full_name: insured.full_name,
      };
      this.sinister_rep = null;
      this.sinister_rep_dlg_ldg = true;
      this.sinister_rep_dlg = true;

      Axios.get(
        URL_API +
          "/insureds/sinister/report?insured_id=" +
          insured.id +
          "&icd_id=0",
        headersToken(this.log.token)
      ).then((rsp) => {
        this.sinister_rep = rsp.data.data;
        this.sinister_rep_dlg_ldg = false;
      });
    },

    letterView() {
      // CAMBIAR A BASE 64
      window
        .open(this.url_pdf + "/carta/servicios/" + this.data.id, "_blank")
        .focus();
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.log.token)
    ).then((rsp) => {
      this.faqs = rsp.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>